section.blob-section {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.blob {
    background-image: linear-gradient(120deg, #ff8a00 0%, #e52e71 100%); 
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: 370px; 
    height: 358px;
    animation: morph 5s linear infinite; 
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
}

.blob:before,
.blob:after{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0; 
  top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(0, 102, 255, 0.21);
  will-change: border-radius, transform, opacity;
  animation-delay: 3s;
  background-image: linear-gradient(120deg, #02207c 0%,  #043ff8 100%);
  /* background-image: linear-gradient(120deg,rgba(0,67,255,.55) 0%, rgba(0,103,255,.89) 100%); */

  
}

.blob:before{
    animation: morph 60s linear infinite;
    opacity: .21;
    animation-duration: 1.5s;
}


@keyframes morph{
    0%,100%{
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
      transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    34%{
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
      transform:  translate3d(0,5px,0) rotateZ(0.01deg);
    }
    50%{
      opacity: .89;
      transform: translate3d(0,0,0) rotateZ(0.01deg);
    }
    67%{
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
      transform: translate3d(0,-3px,0) rotateZ(0.01deg);
    }
  }
  


