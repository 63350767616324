.project-content {
    /* background-image: linear-gradient( var(--color-tertiary), #fff);  */
    background-color: var(--color-tertiary);
}
.project-content .quote-section{
    background-color: transparent;
}

.project-section {
    padding: 10% 5%;
}
.project-section p{
    margin: 0 !important;
}

.project-section-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.project-section-text .text-head {
    font-family: var(--title-font-family);
    font-size: 1.8rem;
    font-weight: 600;
}
.project-section-text .text-main {
    font-family: var(--text-font-family);
    font-size: 1.1rem;
}
.project-section-text .subsequent-paragraph {
    padding-top: 3%;
}
.project-section-text .text-main.italic-paragraph { /*CHECK THIS - WHERE IS IT USED*/
    font-style: italic;
    padding: 3% 0;
}
.bold-text {
    font-weight: 600;
}

.sub-area {
    padding-top: 10%;
}
.sub-area .text-subhead {
    font-size: 1.5rem;
    font-weight: 400;
}

.project-section ul:not(.normal-ul) {
    padding-left: 0;
}
.project-section-text ul:not(.normal-ul) li {
    margin-bottom: 1.5rem;
    list-style-type: none;
}
.project-section-text ul:not(.normal-ul) li .point-subhead{
    font-size: 1.3rem;
    padding-bottom: 0.2rem;
    border-bottom: solid var(--color-text) 1px;
}
.normal-ul {
    list-style: disc;
    margin: 0;
}
.normal-ul li,
.normal-ul.sub-ul li
 {
    font-family: var(--text-font-family);
    font-size: 1.1rem;
}
.normal-ul.sub-ul {
    list-style: circle;
}
.normal-ul li:not(:last-child) { 
    margin-bottom: 1rem;
}
.normal-ul.sub-ul li:first-child { 
    margin-top: 0.5rem;
}
.normal-ul.sub-ul li:not(:last-child) { 
    margin-bottom: 0.5rem;
}

.normal-ul li:has(img),
.text-and-img {
    display: flex;
    flex-direction: column;
}
.text-and-img.text-and-img-top-margin {
    margin-top: 3%;
}

.single-point-ul {
    list-style: disc;
} 
.single-point-ul-title {
    font-size: 1.2rem;
    padding-bottom: 0.2rem;
}


/* slideUpOnLoad animation on index.css */
.project-section.slide-up-on-load .project-section-text {
    animation: slideUpOnLoad 1s ease forwards;
}

.project-section.to-be-scrolled,
.project-section .to-be-scrolled {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.project-section.slide-up.to-be-scrolled,
.project-section .slide-up.to-be-scrolled {
    opacity: 1;
    transform: translateY(0);
}


@media(min-width: 992px) {
    .project-section {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .project-section.about-head {
        padding-bottom: 0;
    }
    .project-section-text {
        width: 60%;
    }
    .normal-ul li:has(img){
        flex-direction: row;
    }
    .normal-ul li:has(img) .li-text {
        width: 80%;
    }
    .text-and-img:has(img)  img{
        width: 80%;
    }
    .text-and-img:has(img)  {
        flex-direction: row;
        align-items: start;        
    }
    .text-and-img:has(img) div {
        width: 80%;
    }
}

@media(min-width: 1200px) {
    .sub-area {
        padding-top: 5%;
    }
}
