.sentence-section p {
    margin: 0 !important;
}

.sentence-section {
    padding: 10% 5%;
    background-color: var(--color-tertiary);
}
.sentence-section.about-head ,
.sentence-section.project-head {
    padding-top: 5%;
    padding-bottom: 5%;
}
.sentence-section.values-text {
    padding-bottom: 3%;
}


.highlight-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.highlight-text .text-head {
    font-family: var(--title-font-family);
    font-size: 2.5rem;
    font-weight: 600;
}
.experience-head .highlight-text .text-head {
    font-size: 2rem;
}
.highlight-text .text-main {
    font-family: var(--title-font-family);
    font-size: 1.5rem;
    font-weight: 400;
}

.actionbutton {
    margin-top: 1rem;
}
.highlight-text a {
    width: fit-content;
    align-self: flex-end;
}

/* slideUpOnLoad animation on index.css */
.sentence-section.slide-up-on-load .highlight-text {
    animation: slideUpOnLoad 1s ease forwards;
}

.sentence-section.to-be-scrolled .highlight-text{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.slide-up .sentence-section.to-be-scrolled .highlight-text{
    opacity: 1;
    transform: translateY(0);
}

@media(min-width: 576px) {
    .experience-head .highlight-text .text-head {
        font-size: 2.5rem;
    }
}

@media(min-width: 992px) {
    .sentence-section {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .sentence-section.about-head,
    .sentence-section.project-head {
        padding-bottom: 0;
    }
    .highlight-text {
        width: 60%;
    }
    .highlight-text a {
        align-self: start;
    }
    
}

