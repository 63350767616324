.navbar {
    transition: height 0.3s ease;
}
.navbar .navbar-nav {
    align-items: center;
}

#navbarMain {
    padding-right: 16px;
}

.navbar-toggler {
    margin-right: 16px;
}

.navbar .navbar-toggler, 
.navbar .navbar-toggler:focus, 
.navbar .navbar-toggler:active, 
.navbar-toggler-icon:focus {
    border: none;
    box-shadow: none;
}

.navbar a,
.navbar-menu a {
    font-family: var(--title-font-family);
    color: var(--color-text);
}

.navbar a:not(.navbar-brand):after
{
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    transition: width 550ms cubic-bezier(0.85,0,0,1);
    -webkit-transition: width 550ms cubic-bezier(0.85,0,0,1);
    width: 0;
    color: var(--color-accent);
}
.navbar a:active:after,
.navbar a.active:after,
.navbar a:hover:after
{
    width: 100%;
}

.split-li {
    position: relative;
}
.split-li span::after {
    content: '';
    position: absolute;
    background-color: var(--color-text);
}
.navbar .split-li span::after {
    transform: translateY(-50%);
    height: 100%;
    width: 1px;
}


.navbar-collapsed, .navbar-menu {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
.navbar-menu {
    background: rgba(255, 255, 255, 0.5);
}

.nav-logo {
    width: 50px;
    height: 50px;
    margin: 8px 8px 8px 24px;
    transition: height 0.3s ease, width 0.3s ease;
}
.navbar-collapsed .nav-logo {
    width: 30px;
    height: 30px;
}


.navbar-menu {
    position: fixed;
    top: 72px;
    width: 100%;
    padding: 3%;
    text-align: center;
    z-index: 90000;
    font-weight: 600;
    font-size: 1.2rem;
}
.navbar-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.navbar-menu ul li {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
}

.navbar-menu .split-li span::after {
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
}

.navbar-menu a {
    transition: background-color ease 1s, border ease 0.3s;
    border-bottom: solid 2px transparent;
}
.navbar-menu a:active,
.navbar-menu a.active,
.navbar-menu a:hover {
    background-color: rgba(204, 210,229, 0.8);
    border-bottom: solid 2px var(--color-accent);
}

@media (min-width: 768px) {
    .navbar-menu {
        display: none;
    }

    /*.nav .portfolio-menu-item {
        position: relative;
    }

     .nav .portfolio-menu-item .portfolio-dropdown {
        position: absolute;
        top: 3em; /* Adjust the distance between the portfolio menu item and the dropdown */
       /* left: 0;  Align the dropdown horizontally with the portfolio menu item */
        /* transform: translateX(-50%); Center the dropdown horizontally */
        /*background-color: var(--color-primary);
        padding: 10px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
        border-radius: 5px;
      }
      
      .nav .portfolio-dropdown ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      
      .nav .portfolio-dropdown ul li a {
        display: block;
        padding: 10px 20px;
        color: var(--color-tertiary);
        text-decoration: none;
      }
      
      .nav .portfolio-dropdown ul li a:hover {
        background-color: var(--color-secondary);
        color: var(--color-primary);
      } */
}


