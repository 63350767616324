.work {
    padding: 10% 5%;
    background-color: var(--color-tertiary);
}

.work h3 {
    color: var(--color-text);
    font-weight: 600;
    font-size: 2.5rem;
}

.work-section {
    display: flex;
    opacity: 0;
    flex-direction: column;
    border-radius: 30px;
    padding: 5%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    margin-top: 10%;
    transform: translateY(50px);
    transition: box-shadow 0.5s ease, opacity 1s ease, transform 1s ease;
}
.work-section:hover {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
}
.work-section.slide-up {
    opacity: 1;
    transform: translateY(0);
}

.work-section .img-section {
    width: 100%;
    display: flex;
    justify-content: center;
}
.work-section .img-section img{
    height: 100%;
    max-height: 500px;
}


.work-text {
    padding: 5% 0;
    display: flex;
    flex-direction: column;
}
.work-text h4 {
    font-weight: 600;
    font-size: 1.5rem;
}

.work-text button {
    align-self: center;
}

.work-text a {
    text-decoration: none;
    color: #fff;
    width: fit-content;
    align-self: flex-end;
}


@media(min-width: 768px) {
    .work-section {
        flex-direction: row;
        align-items: center;
        padding: 3%;
        margin-top: 5%;
    } 
    .work-section .img-section img{
        height: 100%;
        max-height: 300px;
    }
    .work-section .img-section {
        width: 30%;
    }
    .work-text {
        width: 60%;
        margin-left: 5%;
    }
    .work-text button {
        align-self: start;
    }
    .work-text a {
        align-self: start;
    }

}

@media(min-width: 992px) {
    .work {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

@media(min-width: 1200px) {
    .work-section .img-section {
        width: 20%;
    }
}
@media(min-width: 1400px) {
.work-text {
        margin-left: 2%;
    }
}