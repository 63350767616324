.blob-image {
    background: var(--color-tertiary);
    border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.blob-image img {
    width: 100%;
}


@media(min-width: 768px) {
    .blob-image {    
        width: 100%;
    }
}

@media(min-width: 1024px) {
    .blob-image { 
        top: -10%;   
    }
}

/* slideUpOnLoad animation on index.css */
.slide-up-on-load .blob-image {
    opacity: 0;
    animation: slideUpOnLoad 1s ease forwards;
    animation-delay: 1s;
}

.blob-img-animate .blob-image {
    animation: morph 6s linear infinite;
}

@keyframes morph{
    0%, 100% {
        border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
    }
    34% {
        border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    }
}