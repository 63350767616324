.scroll-box {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.scroll-box.visible {
    opacity: 1;
}

.scroll-indicator {
  height: 50px;
  width: 35px;
  border-radius: 50px;
  border: 2px solid;
  border-color: grey;
  position: relative;
}

.scroll-indicator:before {
    content: '';
    position: absolute;
    left: calc(50% - 50px / 20);
    top: 10px;
    height: 5px;
    width: 5px;
    background: grey;
    border-radius: 50%;
    animation: scroll 2s infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(8px);
  }
}