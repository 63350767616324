.header {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    align-items: center;
    background: var(--color-tertiary);
    /* border: solid green; */
}

.header .header-text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    color: var(--color-accent);
    text-shadow: 0px 0px 5px rgba(47,14, 7, 0.3);
}
/* .gradient-text {
    background-image: linear-gradient(to right, #f2711c, #e94057); 
    -webkit-background-clip: text; 
    background-clip: text;
    color: transparent; 
} */


.title h1 {
    font-size: 70px;
    font-weight: 700;
    font-family: var(--header-font-family);
}
.name-occupation {
    display: flex;
    align-items: baseline;
}

.subtitle {
    font-size: 35px;
    font-weight: 100;
    margin-left: 30px;
    transition: transform 1.3s ease, transition-delay 0.2s;
    font-family: var(--title-font-family);
}
.subtitle.big-screen {
    display: none;
}

.scroll-box {
    position: absolute;
    bottom: 5%;
    /* left: calc(50% - 50px); */
}


@media(min-width: 992px) {
    .title h1 {
        font-size: 100px;
    }
    .title h1.name {
        margin-right: auto;
        transition: transform 1s ease;
    }
    .title h1.surname {
        margin-left: auto;
        transition: transform 1s ease;
    }

    .title:hover h1.name {
        transform: translateX(-30px);
    }
    .title:hover h1.surname {
        transform: translateX(30px);
    }
    .title:hover .subtitle {
        transform: translateX(20px);
    } 
    
    .subtitle {
        font-size: 45px;
    }
    .subtitle.small-screen {
        display: none;
    }
    .subtitle.subtitle.big-screen {
        display: block;
    }
    
}


@media(min-width: 1500px) {
    .title {
        width: 50%;
    }
    
}