html {
  --color-primary: rgb(255, 255, 255, 100%);
  --color-secondary: rgb(38, 40, 221, 100%);
  --color-tertiary: rgb(204, 210,229, 100%);
  --color-accent: rgb(233, 72, 35, 100%);
  /* --color-accent-dark: rgb(200, 148, 92, 100%);
  --color-accent-darkest: rgb(178, 131, 82, 100%); */
  --color-text:rgb(11, 18, 21, 100%);
  --header-font-family: 'Oswald', 'sans-serif';
  --title-font-family: 'Montserrat', 'sans-serif';
  --text-font-family: 'Open Sans', sans-serif;
}

html, body {
  padding: 0;
  margin: 0;
  background-color: var(--color-tertiary);
}

h1, h2, h3, h4, h5 {
  font-family: var(--title-font-family);
}

p, button, a {
  font-family: var(--text-font-family);
  text-decoration: none;
}


button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@keyframes slideUpOnLoad {
  from {
      transform: translateY(50px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}