.images-rail-section {
    width: 100%;
    padding-top: 5%;
}
.images-rail-section .label {
    color: gray;
}

.images-rail {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
}
.images-rail.keepColumn {
    flex-direction: column;
}
  
.images-rail .rail-img-container { 
    flex: 0 0 48%;
    margin-bottom: 2%;
}
.images-rail:not(.keepColumn) .rail-img-container:nth-child(odd) { 
    margin-right: 2%;
}


.images-rail img {
    width: 100%;
}

.images-rail.to-be-scrolled .rail-img-container {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}

.slide-up .images-rail.to-be-scrolled .rail-img-container {
    opacity: 1;
    transform: translateY(0);
}

.images-rail.hide-last .rail-img-container:last-child {
    display: none;
}

@media (min-width: 780px) {
    .images-rail .rail-img-container { 
        flex: 0 0 23.5%;
    }
    .images-rail .rail-img-container:nth-child(odd) {
        margin-right: 0;
    }
    .images-rail:not(.keepColumn) .rail-img-container:not(:last-child) { 
        margin-right: 2%;
    }
}

@media (min-width: 992px){
    .images-rail-section {
        padding-top: 3%;
    }
}

