.about {
    background-color: var(--color-tertiary);
}

.about .head {
    padding-top: 20%;
    padding-bottom: 10%;
}

.about .about-section {
    display: flex;
    align-items: center;
    flex-direction: column; 
}

.blob-img-section {
    width: 90%;
}

.about .values-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10%;
}

.about .abt-imgs-section {
    padding: 5%;
    align-items: center;
}


@media(min-width: 780px) {
    .about .head {
        padding-top: 72px;
    }
}

@media(min-width: 992px) {
    .about .head {
        align-items:flex-start;
        padding-bottom: 5%;
    }
    .blob-img-section {
        width: 60%;
        margin-right: 5%;
        align-self: flex-end;
    }
    .about .values-section {
        padding-bottom: 5%;
    }
    .about .abt-imgs-section .abt-imgs {
        max-width: 80%;
    }
}


@media(min-width: 1200px) {
    .blob-img-section {
        width: 40%;
        margin-right: 10%;
    }
    .about .abt-imgs-section .abt-imgs {
        max-width: 60%;
    }
}


