
.quote-section {
    background-color: var(--color-tertiary);
    color: var(--color-text);
    padding: 10% 5%;
    text-align: left;
    width: 100%;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.slide-up .quote-section {
    opacity: 1;
    transform: translateY(0);
}
.quote-section p {
    font-family: var(--title-font-family);
    font-size: 2.5rem;
    font-weight: 600;
}

.quote-section .author {
    font-size: 2rem;
    font-weight: 100;
}

.quote-section.project-quote {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 0;
}
.quote-section.project-quote p {
    font: var(--text-font-family);
    font-size: 1.5rem;
    font-weight: 100;
}

@media(min-width: 992px) {
    .quote-section {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .quote-section p {
        width: 60%;
    }
    .quote-section.project-quote {
        padding-top: 1%;
        padding-bottom: 1%;
    }
}
