footer {
    background-color: var(--color-tertiary);
    color: var(--color-text);
    padding: 10% 0 50px 0;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-content h4 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1em;
}

.social-media {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.square {
    position: relative;
    margin: 0 3%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.content {
    position: relative;
    padding: 5%;
    height: 100%;
    color: #fff;
    text-align: center;
    transition: 0.5s ease;
    z-index: 1000;
}

.square img {
    width: 55%;
}

.square span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid;
    border-color: var(--color-text);
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transition: 0.5s ease;
}

.linkedin:hover span:nth-child(1) {
    border-color: rgba(0,119, 181, 1);
    background: rgba(0,119, 181, 1);
}

.linkedin:hover img {
    content: url('../../Images/Social/linkedin-white.png');
}

.email:hover span:nth-child(1) {
    border: none;
    background: rgba(212, 70, 56, 1);
}

.email:hover img {
    content: url('../../Images/Social/email-white.png');
}


@media (min-width: 768px) {
    .footer-content h4 {
        font-size: 1.8rem;
    }
    .square {
        width: 150px;
        height: 150px;
    }
}