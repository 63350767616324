.image-section {
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
}
.image-section .label {
    color: gray;
}

.single-img {
    width: 100%;
    max-width: 1200px;
}

.single-img.prototype-img {
    width: 60%;
    align-self: center;
}
.image-section:has(> .single-img.prototype-img) {
    padding-top: 3%;
}

.single-img img {
    width: 100%;
}


.single-img .to-be-scrolled img {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}

.slide-up .single-img.to-be-scrolled img {
    opacity: 1;
    transform: translateY(0);
}

.goodreads-userflow {
    margin-bottom: 5%;
}

@media (min-width: 768px) {
    .single-img.prototype-img {
        width: 50%;
    }
}
@media (min-width: 992px) {
    .single-img.maxWidth60 {
        width: 60%;
    }
    .image-section {
        padding-top: 3%;
    }

    .image-section:has(> .single-img.prototype-img) {
        padding-top: 0;
    }
    .single-img.prototype-img {
        width: 80%;
    }
    .single-img.single-img.prototype-img img {
        max-height: 700px;
        width: auto;
    }
}
