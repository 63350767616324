.bullet-points-section {
    padding: 10% 5%;
}

.bullet-points .text-head {
    font-family: var(--title-font-family);
    font-size: 2rem;
    font-weight: 600;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.bullet-points .text-head.slide-up {
    opacity: 1;
    transform: translateY(0);
}

.bullet-points ul {
    list-style-type: none;
    padding-left: 0;
}
.bullet-points li {
    font-family: var(--text-font-family);
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.bullet-points .point-title {
    font-size: 1.5rem;
    font-weight: 600;
}
.bullet-points .point-text{
    font-size: 1.1rem;
    font-weight: 400;
}


.bullet-points li.slide-up{
    opacity: 1;
    transform: translateY(0);
}

@media(min-width: 576px) {
    .bullet-points .text-head {
        font-size: 2.5rem;
    }
    .bullet-points .point-text{
        font-size: 1.2rem;
    }
}

@media(min-width: 992px) {
    .bullet-points {
        width: 60%;
    }
    .bullet-points-section {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}
