
.boxes-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 5%;
    width: 100%;
}

.boxes-section .single-box { 
    flex: 0 0 100%;
    padding: 5%;
    opacity: 0;
    border: 2px solid;
    font-family: var(--text-font-family);
    border-image: linear-gradient(120deg,  #ff8a00 0%, #e52e71 100%);
    border-image-slice: 1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.boxes-section  .single-box:not(:last-child) {
    margin-bottom: 1rem;
}

.boxes-section  .single-box::-webkit-scrollbar {
    display: none;
}

.boxes-section  .single-box.slide-up {
    opacity: 1;
    transform: translateY(0);
}

.boxes-section .single-box h5 {
    font-weight: 800;
    font-size: 1.5rem;
    color: var(--color-text);
} 

.boxes-section .single-box p{
    font-weight: 400;
    font-size: 1.1rem;
}
.boxes-section .single-box .value-key-phrase {
    font-weight: 600;
}


@media(min-width: 768px) {
    .boxes-section .single-box { 
        flex: 0 0 48%;
        margin-bottom: 1rem;
    }
    .boxes-section .single-box p{
        font-size: 1.2rem;
    }
     .boxes-section .single-box:nth-child(odd) { 
        margin-right: 2%;
    }

}

@media(min-width: 1200px) {
    .boxes-section .single-box{
        flex: 0 0 38%;
    }
    .boxes-section {
        justify-content: center;
    }
}
