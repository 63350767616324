.small-big-img-section {
    width: 100%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
}
.small-big-img-section .label {
    color: gray;
}
.small-big-img-section .img-container:not(:last-child) {
    margin-bottom: 2%;
}
.small-img-container, 
.big-img-container {
    width: 100%;
}
.small-img-container img,
.big-img-container img {
    width: 100%;
}

.abt-imgs .img-container:first-child {
    display: none;
}

.img-container.to-be-scrolled {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}
.img-container.slide-up.to-be-scrolled {
    opacity: 1;
    transform: translateY(0);
}


@media (min-width: 780px){
    .abt-imgs .img-container:first-child {
        display: block;
    }
    .abt-imgs.small-big-img-section {
        flex-direction: row;
    }
    .abt-imgs .small-img-container {
        width: 40%;
        margin-right: 2%;
    }
    .abt-imgs .big-img-container {
        width: 60%;
    }
}

@media (min-width: 992px){
    .small-big-img-section {
        flex-direction: row;
        padding-top: 3%;
    }
    .small-img-container {
        width: 40%;
        margin-right: 2%;
    }
    .big-img-container {
        width: 60%;
    }
}
