.project-head {
    background-color: var(--color-tertiary);
}

.project-head-section {
    padding-top: 20%;
    padding-bottom: 10%;
    display: flex;
    align-items: center;
    flex-direction: column; 
}
.project-cover-img {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 2%;
}
.project-cover-img img{
    width: auto;
    height: 100%;
    max-height: 500px;
    margin: auto;
}

@media(min-width: 780px) {
    .project-head-section {
        padding-top: 72px;
    }
    .project-cover-img img{
        max-height: 700px;
    }
}

@media(min-width: 992px) {
    .project-head-section {
        align-items:flex-start;
        padding-bottom: 5%;
    }
    .project-cover-img {
        width: 60%;
        margin-right: 5%;
        align-self: flex-end;
    }
    .project-cover-img {
        margin-top: 0;
    }
    
}


@media(min-width: 1200px) {
    .project-cover-img {
        width: 40%;
        margin-right: 10%;
    }
}


