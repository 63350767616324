.more-projects-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    padding: 10% 5%;
    background-color: var(--color-tertiary);
}

.more-projects-section h3 {
    color: var(--color-text);
    font-weight: 600;
    font-size: 1.5rem;
}

.next-projects-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.next-project {
    display: flex;
    /* opacity: 0; */
    flex-direction: row;
    align-items: center;
    border-radius: 30px;
    padding: 5%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    margin-top: 5%;
    /* transform: translateY(50px); */
    transition: box-shadow 0.5s ease, opacity 1s ease, transform 1s ease;
}
.next-project:hover {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
}
/* .next-project.slide-up {
    opacity: 1;
    transform: translateY(0);
} */
  
.next-project .next-img-section {
    display: flex;
    justify-content: right;
}
.next-project img {
    max-height: 300px;
}


.link-button {
    text-decoration: none;
}
.project-title {
    margin-left: 3%;
}
.project-title h4 {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--color-text);
}


@media(min-width: 576px) {
    .project-title h4 {
        font-size: 1.5rem;
    }
}

@media(min-width: 992px) {
    .more-projects-section {
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .next-projects-section {
        flex-direction: row;
    }
    .next-project {
        padding: 3%;
    }
    .link-button {
        width: 100%;
    }
    .link-button:nth-child(odd) {
        margin-right: 5%;
    }
}

@media(min-width: 1200px) {
    .more-projects-section h3 {
        font-size: 2rem;
    }
    .project-title h4 {
        font-size: 2.3rem;
    }
}