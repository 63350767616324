.button_slide {
    display: inline-block;
    padding: 16px;
    cursor: pointer;
    font-family: var(--text-font-family);
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--color-text);
    border: 2px solid;
    border-image: linear-gradient(120deg, #02207c 0%,  #043ff8 100%);
    border-image-slice: 1; 
    border-radius: 0;
    box-shadow: inset 0 0 0 0 var(--color-secondary);

    -webkit-transition: box-shadow ease-out 0.8s, color ease 0.4s, border-image ease-in-out 0.4s;
    -moz-transition: box-shadow ease-out 0.8s, color ease 0.4s, border-image ease-in-out 0.4s;
    transition: box-shadow ease-out 0.8s, color ease 0.4s, border-image ease-in-out 0.4s;
}

.button_slide:hover {
    color: white;
    border-image: none;
    border-color: var(--color-secondary)
}

.slide_right:hover {
    box-shadow: inset 400px 0 0 0 var(--color-secondary);
}

